import { HubConnection } from "@microsoft/signalr";
import { INotification } from "types/models/Notification";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_CONNECT_NOTIFICATION_CHAT = "GET_CONNECT_NOTIFICATION_CHAT";

export interface GetNotificationActions {
  type: typeof GET_NOTIFICATION;
  payload: INotification;
}

export interface GetConnectNotificationActions {
  type: typeof GET_CONNECT_NOTIFICATION_CHAT;
  payload: HubConnection | undefined;
}

export type NotificationActionsTypes =
  GetNotificationActions |
  GetConnectNotificationActions;