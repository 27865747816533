import React, { createContext, ReactNode, useContext, useEffect, useState, useRef } from 'react';
import useCommonStore from '../../../../zustand/reducers/Common';
import { appIntl } from '../../../utility/Utils';
import { setAuthToken, jwtLogin } from './jwt-api';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_USER = {
  id: 1,
  uid: '1',
  displayName: 'Admin',
  email: 'admin@gmail.com',
  photoURL: '',
  role: 'admin'
}

interface JWTAuthContextProps {
  user: any | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  data: any[];
}

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  userName: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  data: []
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => { },
  signInUser: () => { },
  logout: () => { },
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    data: []
  });
  const { fetchStart, fetchError, fetchSuccess } = useCommonStore((state) => state)
  const { messages } = appIntl();
  const shouldLog = useRef(true);

  useEffect(() => {
    const getAuthUser = async () => {
      if (shouldLog.current) {
        shouldLog.current = false;
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem("refreshToken");
        const info: any = localStorage.getItem('info');

        if (!token || !refreshToken || !info) {
          setJWTAuthData({
            user: DEFAULT_USER,
            isLoading: false,
            isAuthenticated: false,
            data: []
          });
        } else {
          setAuthToken(token, refreshToken);
          const tempRes = await jwtLogin.get(`/api/v1/accounts/account/role/${encodeURIComponent(JSON.parse(info || {})?.userName)}`);
          setJWTAuthData({
            user: JSON.parse(info || {}),
            isLoading: false,
            isAuthenticated: true,
            data: tempRes.data.data?.map((item: any) => {
              return {
                ...item,
                id: uuidv4(),
              }
            })
          });
        }
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ userName, password }: any) => {
    fetchStart();
    try {
      const { data } = await jwtLogin.post("/api/v1/accounts/login", { userName, passWord: password });
      if (data.succeeded) {
        setAuthToken(data.data.token, data.data.refreshToken);
        localStorage.setItem('info', JSON.stringify({ ...DEFAULT_USER, ...data.data }));
        const tempRes = await jwtLogin.get(`/api/v1/accounts/account/role/${encodeURIComponent(userName)}`);
        setJWTAuthData({
          user: { ...DEFAULT_USER, ...data.data },
          isAuthenticated: true,
          isLoading: false,
          data: tempRes.data.data?.map((item: any) => {
            return {
              ...item,
              id: uuidv4(),
            }
          })
        });
        fetchSuccess();
      } else {
        setJWTAuthData({
          ...firebaseData,
          isAuthenticated: false,
          isLoading: false,
        });
        fetchError(data?.message || String(messages["message.somethingWentWrong"]));
      }
    } catch (error: any) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      console.log(error);
      fetchError(error?.message || String(messages["message.somethingWentWrong"]));
    }
  };

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
      data: []
    });
    window.location.reload();
  };
  const signUpUser = () => {
  }

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
