import React, { useEffect, useState } from 'react'
import { ReactComponent as AvatarDefault } from 'assets/user/avatar-default.svg';
import { DoubleRightOutlined, DoubleLeftOutlined, WifiOutlined } from '@ant-design/icons';
import { observer } from "mobx-react-lite";
import useSound from 'use-sound';
import CallSound from '../../assets/sound/call-sound.mp3';

import './index.style.less';
import { Avatar, Button } from 'antd';
import useAgoraStore from '../../zustand/reducers/Agora';
import useAgora from 'hooks/useAgora';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useAuthUser } from '@leo/utility/AuthHooks';
import ModalReceiveCall from './ModalReceiveCall';
import ModalCall from './ModalCall';
import ModalPending from './ModalPending';

const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });


export const pad = (num) => {
  return ("0" + num).slice(-2);
}
export const formatHHmm = (secs) => {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${hours !== 0 ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(secs)}`;
}

const Call = () => {
  const [isZoom, setIsZoom] = useState(false);
  const [muted, setMuted] = useState(true);
  const [play, { stop }] = useSound(CallSound, { volume: 0.6, interrupt: false });
  const { user } = useAuthUser();
  const {
    changeInfoCall, changeCustomerCodeCall, changeStatusStartCall, changeOpenCall, getActionCallActions, getActionCall,
    changeInfoTokenCallActions, infoCall, infoTokenCall, openCall, statusStartCall
  } = useAgoraStore((state) => state);
  const {
    leave, join, isUserLeftLeave, localAudioTrack, isUserLeftJoin, joinState,
    localVideoTrack, network, remoteUsers,
  } = useAgora(client);

  const handleZoom = () => {
    setIsZoom(!isZoom);
  }

  const handleLeave = (msg: string, isLeave: boolean, type: string | undefined) => {
    if (joinState) {
      isLeave && leave(msg, type === 'CALL' ? infoCall : infoTokenCall, user, 'LEAVE')
      changeInfoCall(null);
      changeInfoTokenCallActions(null);
      changeOpenCall(false);
      changeStatusStartCall(false);
      changeCustomerCodeCall('');
      if (!statusStartCall) {
        stop();
      }
    }
  }

  const handleAcceptCall = () => {
    changeStatusStartCall(true);
  }
  const handleRefuseCall = () => {
    getActionCallActions({
      actionCall: 'REJECT',
      code: infoTokenCall?.group || '',
      session: infoTokenCall?.session || '',
      userCode: user?.userCode || '',
    })
  }
  const handleEndCall = () => {
    handleLeave('CS dừng kết thúc cuộc gọi!', true, 'RECEIVE');
  }
  const handleOffCall = () => {
    handleLeave('CS dừng kết thúc cuộc gọi!', true, 'RECEIVE');
  }
  // const handleAudio = () => {
  //   localAudioTrack?.setMuted(!localAudioTrack.muted);
  // }

  // React.useEffect(() => {
  //   if (statusStartCall) {
  //     const timer = setTimeout(() => {
  //       setTimeNum(timeNum + 1);
  //     }, 1000);
  //     return () => clearTimeout(timer);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeNum, statusStartCall])

  useEffect(() => {
    if (openCall && infoCall && !joinState) {
      if (infoCall?.typeCall === 'RECEIVE') {
        join(infoCall.appId, infoCall.channelName, infoCall.token, infoCall?.uid, infoCall, user);
      } else {
        join(infoCall.appId, infoCall.channelName, infoCall.token, infoCall?.uid, infoCall, user);
        stop();
      }
    } else {
      setIsZoom(false);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCall?.session])

  useEffect(() => {
    if (localAudioTrack && openCall) {
      localAudioTrack?.setMuted(false);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAudioTrack])

  useEffect(() => {
    if (isUserLeftLeave) {
      handleLeave('CS thoát khỏi cuộc gọi', true, infoCall ? infoCall.typeCall : infoTokenCall ? infoTokenCall.typeCall : '');
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLeftLeave])

  useEffect(() => {
    if (isUserLeftJoin) {
      changeStatusStartCall(true);
      stop();
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLeftJoin]);

  useEffect(() => {
    if (statusStartCall && infoTokenCall) {
      getActionCallActions({
        actionCall: 'ACCEPT',
        code: infoTokenCall?.group || '',
        session: infoTokenCall?.session || '',
        userCode: user?.userCode || '',
      });
      changeCustomerCodeCall(infoCall?.customerId || '');
      localAudioTrack?.setMuted(false);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusStartCall])

  useEffect(() => {
    if (getActionCall.success && getActionCall.data) {
      changeInfoCall({ ...getActionCall.data, customerId: getActionCall?.data?.userName, typeCall: 'RECEIVE' });
      stop();
    } else {
      if (!getActionCall.success && getActionCall.message) {
        changeOpenCall(false)
        stop();
      }
      if (openCall) {
        changeOpenCall(false)
      }
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionCall])

  useEffect(() => {
    if (infoTokenCall) {
      const timer = setTimeout(() => {
        handleLeave('CS nhỡ cuộc gọi', false, 'RECEIVE');
      }, Number(infoTokenCall.duration || 0) * 60000);
      return () => clearTimeout(timer);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoTokenCall])

  useEffect(() => {
    if (isUserLeftLeave) {
      handleLeave('CS thoát khỏi cuộc gọi', true, infoCall ? infoCall.typeCall : infoTokenCall ? infoTokenCall.typeCall : '');
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLeftLeave])

  useEffect(() => {
    if (openCall) {
      play();
    } else {
      stop();
      handleLeave('', true, infoCall ? infoCall.typeCall : 'RECEIVE')
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCall])
  console.log(infoCall)
  return (
    <React.Fragment>
      {openCall ? (
        <div className='receive-call-container' style={{
          width: isZoom ? 'auto' : '100vw',
          height: isZoom ? 'auto' : '100vh',
          left: isZoom ? 10 : 0,
          bottom: isZoom ? 35 : undefined,
          marginLeft: isZoom ? 0 : 'auto',
          marginRight: isZoom ? 0 : 'auto',
          maxWidth: isZoom ? 0 : undefined,
        }}>
          {infoTokenCall?.typeCall === 'RECEIVE' ? (
            <ModalReceiveCall
              isZoom={isZoom}
              handleZoom={handleZoom}
              network={network}
              statusStartCall={statusStartCall}
              handleEndCall={handleEndCall}
              handleAcceptCall={handleAcceptCall}
              handleRefuseCall={handleRefuseCall}
              infoTokenCall={infoTokenCall}
            />
          ) : statusStartCall ? (
            infoCall?.typeCall === 'CALL' ? (
              remoteUsers.length ? (
                <ModalReceiveCall
                  isZoom={isZoom}
                  handleZoom={handleZoom}
                  network={network}
                  statusStartCall={statusStartCall}
                  handleEndCall={handleEndCall}
                  handleAcceptCall={handleAcceptCall}
                  handleRefuseCall={handleRefuseCall}
                  infoTokenCall={infoTokenCall}
                />
              ) : <ModalPending isZoom={isZoom} handleOffCall={handleOffCall} />
            ) : (
              <ModalCall
                client={client} isZoom={isZoom} handleZoom={handleZoom} muted={muted}
                setMuted={setMuted} infoTokenCall={infoTokenCall} infoCall={infoCall}
                localAudioTrack={localAudioTrack} localVideoTrack={localVideoTrack}
                network={network} remoteUsers={remoteUsers}
                leave={leave} joinState={joinState} handleOffCall={handleOffCall}
              />
            )
          ) : (
            <ModalPending isZoom={isZoom} handleOffCall={handleOffCall} />
          )}
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default observer(Call)