import { create } from 'zustand';
import { INotification } from 'types/models/Notification';
import { HubConnection } from '@microsoft/signalr';
import { GET_CONNECT_NOTIFICATION_CHAT, GET_NOTIFICATION, NotificationActionsTypes } from 'types/actions/Notification.actions';

const initialNotification: {
  notiValues: INotification;
  connectNotificationChat: HubConnection | undefined;
} = {
  notiValues: { value: 0 },
  connectNotificationChat: undefined
}

const NotificationReducers = (state = initialNotification, action: NotificationActionsTypes) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      return {
        ...state,
        notiValues: action.payload
      }
    case GET_CONNECT_NOTIFICATION_CHAT:
      return {
        ...state,
        connectNotificationChat: action.payload
      }
    default:
      return state;
  }
};

interface INotificationStore {
  notiValues: INotification;
  connectNotificationChat: HubConnection | undefined;
  changeConnectNoti: (val: HubConnection) => void;
  changeNotificationValues: (val: INotification) => void;
}

const useNotificationStore = create<INotificationStore>((set) => ({
  ...initialNotification,
  notiValues: { value: 0 },
  connectNotificationChat: undefined,
  changeConnectNoti: (val: HubConnection | undefined) => {
    set((state) => NotificationReducers(state, { type: GET_CONNECT_NOTIFICATION_CHAT, payload: val }))
  },
  changeNotificationValues: (val: INotification) => {
    set((state) => NotificationReducers(state, { type: GET_NOTIFICATION, payload: val }))
  }
}))

export default useNotificationStore;
