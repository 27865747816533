import React, { useEffect, useState } from 'react'
import { ReactComponent as AvatarDefault } from 'assets/user/avatar-default.svg';
import { DoubleRightOutlined, DoubleLeftOutlined, WifiOutlined } from '@ant-design/icons';
import { observer } from "mobx-react-lite";
import useSound from 'use-sound';
import CallSound from '../../assets/sound/call-sound.mp3';

import './index.style.less';
import { Avatar, Button } from 'antd';
import useAgoraStore from '../../zustand/reducers/Agora';
import useAgora, { INetwork } from 'hooks/useAgora';
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser, ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';
import { useAuthUser } from '@leo/utility/AuthHooks';
import { formatHHmm } from '.';
import { IInfoCall, IInfoTokenCall } from 'types/models/Agora';
import MediaPlayer from './MediaPlayer';
import ModalPending from './ModalPending';


interface IModalCall {
  handleZoom: () => void;
  isZoom: boolean;
  client: IAgoraRTCClient
  muted: boolean;
  setMuted: (val: boolean) => void;
  infoTokenCall: IInfoTokenCall | null;
  infoCall: IInfoCall | null;
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  network: INetwork;
  remoteUsers: IAgoraRTCRemoteUser[];
  leave: Function;
  joinState: boolean;
  handleOffCall: () => void;
  // statusStartCall: boolean;
  // handleEndCall: () => void;
  // handleAcceptCall: () => void;
  // handleRefuseCall: () => void;
  // infoTokenCall: IInfoTokenCall | null;
}
const ModalCall: React.FC<IModalCall> = ({
  isZoom, handleZoom, network,
  client, muted, setMuted, infoTokenCall, infoCall,
  localAudioTrack, localVideoTrack, remoteUsers, leave, joinState,
  handleOffCall
  // statusStartCall, handleEndCall, handleAcceptCall, handleRefuseCall, infoTokenCall
}) => {
  console.log('remoteUsers')
  console.log(remoteUsers)
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {!remoteUsers.length ? <ModalPending isZoom={isZoom} handleOffCall={handleOffCall} /> : (
        <React.Fragment>
          {joinState && localVideoTrack ? (
            <MediaPlayer label={`Local ${client.uid?.toString()!}`} videoTrack={localVideoTrack} audioTrack={localAudioTrack} type='me' infoTokenCall={infoTokenCall} />
          ) : null}
          {remoteUsers.map(user => (
            <MediaPlayer key={user.uid} label={`Remote ${user.uid.toString()}`} videoTrack={user.videoTrack} audioTrack={user.audioTrack} type='you' infoTokenCall={infoTokenCall} />
          ))}
        </React.Fragment>
      )}
    </div>
  )
}

export default observer(ModalCall)