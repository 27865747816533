import { IResponeListCustomer, IResponeReadCustomer, IResponeFirebase, IResponeImportCustomer } from "../models/Customer";

export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER";
export const READ_CUSTOMER = "READ_CUSTOMER";
export const UPDATE_USER_FIREBASE = "UPDATE_USER_FIREBASE";
export const IMPORT_CUSTOMER = "IMPORT_CUSTOMER";

export interface GetListCustomerAction {
  type: typeof GET_LIST_CUSTOMER;
  listCustomer: IResponeListCustomer
}
export interface ReadCustomerAction {
  type: typeof READ_CUSTOMER;
  readCustomer: IResponeReadCustomer
}

export interface UpdateUserFirebaseActions {
  type: typeof UPDATE_USER_FIREBASE;
  payload: IResponeFirebase;
}

export interface ImportCustomerActions {
  type: typeof IMPORT_CUSTOMER;
  payload: IResponeImportCustomer;
}

export type CustomerActionTypes =
  GetListCustomerAction |
  ReadCustomerAction |
  UpdateUserFirebaseActions |
  ImportCustomerActions;