import { create } from 'zustand';
import { jwtApiV1, jwtApiV1Upload, jwtVoiceApi } from '../../@leo/services/auth/jwt-auth/jwt-api';
import { CustomerActionTypes, GET_LIST_CUSTOMER, IMPORT_CUSTOMER, READ_CUSTOMER, UPDATE_USER_FIREBASE } from "../../types/actions/Customer.actions";
import { IReadCustomerData, IResponeFirebase, IResponeImportCustomer, IResponeListCustomer, IResponeReadCustomer } from '../../types/models/Customer';
import { DEFAULT_LIST_CUSTOMER, DEFAULT_READ_CUSTOMER } from './data';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_RESPONE = {
  success: false,
  message: ''
}

const initialCustomer: {
  listCustomer: IResponeListCustomer;
  readCustomer: IResponeReadCustomer;
  userFirebase: IResponeFirebase;
  importCustomer: IResponeImportCustomer;
} = {
  listCustomer: DEFAULT_LIST_CUSTOMER,
  readCustomer: DEFAULT_READ_CUSTOMER,
  userFirebase: DEFAULT_RESPONE,
  importCustomer: DEFAULT_READ_CUSTOMER
}

const CustomerReducers = (state = initialCustomer, action: CustomerActionTypes) => {
  switch (action.type) {
    case GET_LIST_CUSTOMER:
      return {
        ...state,
        listCustomer: action.listCustomer
      }
    case READ_CUSTOMER:
      return {
        ...state,
        readCustomer: action.readCustomer
      }
    case UPDATE_USER_FIREBASE:
      return {
        ...state,
        userFirebase: action.payload,
      };
    case IMPORT_CUSTOMER:
      return {
        ...state,
        importCustomer: action.payload,
      };
    default:
      return state;
  }
};

interface ICustomerStore {
  listCustomer: IResponeListCustomer;
  readCustomer: IResponeReadCustomer;
  userFirebase: IResponeFirebase;
  importCustomer: IResponeImportCustomer;
  getListCustomer(): Promise<{ succeeded: boolean, message: string }>;
  readCustomerAction(data: FormData): Promise<{ succeeded: boolean, message: string }>;
  updateUserFirebase: (userCode: string, token: string) => Promise<{ succeeded: boolean, message: string }>;
  importCustomerAction: (data: IReadCustomerData[]) => Promise<{ succeeded: boolean, message: string }>;
}

const useCustomerStore = create<ICustomerStore>((set) => ({
  ...initialCustomer,
  listCustomer: DEFAULT_LIST_CUSTOMER,
  getListCustomer: async (page?: number, pageSize?: number, search?: string) => {
    let result = { succeeded: false, message: '' };
    const customer: any = await jwtApiV1.get(`/customer/api/v1/customers/?${page ? `page=${page}` : `page=1`}${pageSize ? `&pageSize=${pageSize}` : `&pageSize=999999`}&search=${search || ''}`)
      .then((data) => {
        if (data.status === 200 && data.data.succeeded && data.data.data) {
          result = { succeeded: true, message: 'Lấy danh sách khách hàng thành công!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_LIST_CUSTOMER;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_LIST_CUSTOMER;
      })
    set((state) => CustomerReducers(state, { type: GET_LIST_CUSTOMER, listCustomer: customer }))
    return result;
  },
  readCustomerAction: async (data: FormData) => {
    let result = { succeeded: false, message: '' };
    const customer: any = await jwtApiV1Upload.post(`/customer/api/v1/customers/read_excels`, data)
      .then((data) => {
        if (data.status === 200 && data.data.succeeded && data.data.data) {
          result = { succeeded: true, message: 'Đã kiểm tra file thành công!' };
          return {
            ...data.data,
            data: data.data.data.map((item) => {
              return {
                ...item,
                id: uuidv4()
              }
            })
          };
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_READ_CUSTOMER;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_READ_CUSTOMER;
      })
    set((state) => CustomerReducers(state, { type: READ_CUSTOMER, readCustomer: customer }))
    return result;
  },
  importCustomerAction: async (data: IReadCustomerData[]) => {
    let result = { succeeded: false, message: '' };
    const customer: any = await jwtApiV1.post(`/customer/api/v1/customers/imports`, data)
      .then((data) => {
        if (data.status === 200 && data.data.succeeded && data.data.data) {
          result = { succeeded: true, message: 'Thêm khách hàng thành công!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_READ_CUSTOMER;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_READ_CUSTOMER;
      })
    set((state) => CustomerReducers(state, { type: IMPORT_CUSTOMER, payload: customer }))
    return result;
  },
  updateUserFirebase: async (userCode: string, token: string) => {
    let result = { succeeded: false, message: '' };
    const fire: any = await jwtVoiceApi.post(`/v2/api/users/fire_base`, {
      userCode: userCode,
      firebase: token
    })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          result = { succeeded: true, message: 'Đã tải trạng thái firebase!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_READ_CUSTOMER;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_READ_CUSTOMER;
      })
    set((state) => CustomerReducers(state, { type: UPDATE_USER_FIREBASE, payload: fire }))
    return result;
  },
}))

export default useCustomerStore;
