import { authRouteConfig } from "./auth";
import { initialUrl } from "../shared/constants/AppConst";

import { errorPagesConfigs } from "./errorPages";
import { doctorConfig } from "./main/doctor";
import { customerConfig } from "./main/customer";
import { specialistConfig } from "./main/specialist";
import { symptomConfig } from "./main/symptom";
import { hospitalConfig } from "./main/hospital";
import { codeConfig } from "./main/code";
import { messageConfig } from "./main/message";
import { settingConfig } from "./main/setting";
import { accountConfig } from "./main/account";
import { listRequestConfig } from "./main/list-request";
import { createRequestConfig } from "./main/create-request";
import { productConfig } from "./main/product";
import { calendarsConfig } from "./main/calendar";
import { listRequestTMAConfig } from "./main/list-requests-tma";
import { dashboardConfig } from "./main/dashboard";
import { oneCareShippingConfig } from "./main/one-care-shipping"
import Error403 from "./errorPages/Error403";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardConfig,
    ...doctorConfig,
    ...customerConfig,
    ...symptomConfig,
    ...specialistConfig,
    ...hospitalConfig,
    ...codeConfig,
    ...messageConfig,
    ...settingConfig,
    ...accountConfig,
    ...listRequestConfig,
    ...createRequestConfig,
    ...productConfig,
    ...calendarsConfig,
    ...listRequestTMAConfig,
    ...oneCareShippingConfig
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export {
  authorizedStructure,
  unAuthorizedStructure,
  anonymousStructure
};
