import React, { useEffect, useState } from 'react'
import { ReactComponent as AvatarDefault } from 'assets/user/avatar-default.svg';
import { DoubleRightOutlined, DoubleLeftOutlined, WifiOutlined } from '@ant-design/icons';
import { observer } from "mobx-react-lite";

import './index.style.less';
import { Avatar, Button } from 'antd';
import { formatHHmm } from '.';
import { IInfoTokenCall } from 'types/models/Agora';


interface IModalReceiveCall {
  isZoom: boolean;
  network: any;
  statusStartCall: boolean;
  handleEndCall: () => void;
  handleAcceptCall: () => void;
  handleRefuseCall: () => void;
  handleZoom: () => void;
  infoTokenCall: IInfoTokenCall | null
}
const ModalReceiveCall: React.FC<IModalReceiveCall> = ({
  isZoom, network, statusStartCall, handleEndCall, handleAcceptCall, handleRefuseCall, handleZoom, infoTokenCall
}) => {
  const [timeNum, setTimeNum] = useState(0);

  useEffect(() => {
    if (statusStartCall) {
      const timer = setTimeout(() => {
        setTimeNum(timeNum + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeNum, statusStartCall])

  return (
    <div className='box-call-info-container' style={{ maxWidth: isZoom ? 400 : undefined, display: isZoom ? 'block' : 'flex' }}>
      <div className='box-call-info-item' style={{
        padding: isZoom ? 6 : 24,
        backgroundColor: isZoom ? '#081D34' : '#0049C6',
        position: isZoom ? 'absolute' : 'relative',
        bottom: 0
      }}>
        {!isZoom ? (
          <React.Fragment>
            <WifiOutlined style={{ color: network.color }} />
            <p style={{ fontSize: 12, fontWeight: 600, color: 'white', lineHeight: '18px' }}>Cuộc gọi - {formatHHmm(timeNum)}</p>
            <Avatar size={72} className='cr-user-info-avatar' src={<AvatarDefault style={{ width: 72, height: 72 }} />} />
            <p className='name-customer'>{infoTokenCall?.nameCaller || 'Không xác định'}</p>
            <div className='list-action'>
              {statusStartCall ? (
                <Button onClick={handleEndCall} className='refuse-btn'>Kết thúc cuộc gọi</Button>
              ) : (
                <React.Fragment>
                  <Button onClick={handleAcceptCall} className='accept-btn'>Nhận cuộc gọi</Button>
                  <Button onClick={handleRefuseCall} className='refuse-btn'>Từ chối</Button>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className='name-customer'>{infoTokenCall?.nameCaller || 'Không xác định'}</p>
          </React.Fragment>
        )}
        <Button style={{ position: 'absolute', right: -40, top: 0, borderRadius: 8 }} onClick={handleZoom} icon={isZoom ? <DoubleRightOutlined /> : <DoubleLeftOutlined />} />
      </div>
    </div>
  )
}

export default observer(ModalReceiveCall)