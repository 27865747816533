import { useState, useEffect } from 'react';
import AgoraRTC, {
  IAgoraRTCClient, IAgoraRTCRemoteUser, MicrophoneAudioTrackInitConfig, CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack, ILocalAudioTrack, LocalAudioTrackStats, ConnectionState, ConnectionDisconnectedReason, ILocalVideoTrack, ICameraVideoTrack
} from 'agora-rtc-sdk-ng';
import useCommonStore from '../zustand/reducers/Common';
import useAgoraStore from '../zustand/reducers/Agora';

export interface INetwork {
  status: number;
  title: string;
  color: string;
}

const DEFAULT_NETWORK = { status: 0, title: 'Đang kiểm tra chất lượng đường truyền', color: '#979797' }

const returnStatusNetwork = (val: number) => {
  switch (val) {
    case 0: return 'Đang kiểm tra chất lượng đường truyền';
    case 1: return 'Đường truyền tốt';
    case 2: case 3: case 4: case 5: case 6: case 7: return 'Đường truyền không tốt';
    default: return 'Đang kiểm tra chất lượng đường truyền';
  }
}

export default function useAgora(client: IAgoraRTCClient | undefined)
  : {
    localAudioTrack: ILocalAudioTrack | undefined,
    localVideoTrack: ILocalVideoTrack | undefined,
    joinState: boolean,
    leave: Function,
    join: Function,
    remoteUsers: IAgoraRTCRemoteUser[],
    localAudioTrackStats: LocalAudioTrackStats | undefined,
    clientRTCStats: LocalAudioTrackStats | undefined,
    isUserLeftLeave: boolean,
    isUserLeftJoin: boolean,
    conn_state: string,
    network: INetwork
  } {
  const { fetchError } = useCommonStore((state) => state);
  const { createCallTracking, changeStatusStartCall } = useAgoraStore((state) => state);
  const [localAudioTrack, setLocalAudioTrack] = useState<IMicrophoneAudioTrack | undefined>(undefined);
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack | undefined>(undefined);
  const [localAudioTrackStats, setLocalAudioTrackStats] = useState<LocalAudioTrackStats | undefined>(undefined);
  const [clientRTCStats, setClientRTCStats] = useState<LocalAudioTrackStats | undefined>(undefined);
  const [isUserLeftLeave, setIsUserLeftLeave] = useState<boolean>(false);
  const [isUserLeftJoin, setIsUserLeftJoin] = useState<boolean>(false);
  const [conn_state, setConn_state] = useState("");
  // 0: The quality is unknown.
  // 1: The quality is excellent.
  // 2: The quality is good, but the bitrate is less than optimal.
  // 3: Users experience slightly impaired communication.
  // 4: Users can communicate with each other, but not very smoothly.
  // 5: The quality is so poor that users can barely communicate.
  // 6: The network is disconnected and users cannot communicate.
  const [network, setNetwork] = useState<INetwork>(DEFAULT_NETWORK);

  const [joinState, setJoinState] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  async function createCallLocalTracks(config?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig)
    : Promise<IMicrophoneAudioTrack[] | undefined[]> {
    return await AgoraRTC.createMicrophoneAudioTrack(config).then((res) => {
      setLocalAudioTrack(res);
      return [res];
    }).catch((err) => {
      console.log('err mic');
      console.log(err?.code);
      return [undefined];
    });
  }

  // ICameraVideoTrack, IMicrophoneAudioTrack
  // Thêm 2 khứa này vào

  AgoraRTC.onCameraChanged = async (changedDevice) => {
    console.log('camera changedDevice');
    // When plugging in a device, switch to a device that is newly plugged in.
    if (changedDevice.state === "ACTIVE") {
      localVideoTrack?.setDevice(changedDevice.device.deviceId);
      // Switch to an existing device when the current device is unplugged.
    } else if (changedDevice.device.label === localVideoTrack?.getTrackLabel()) {
      const oldCameras = await AgoraRTC.getCameras();
      oldCameras[0] && localVideoTrack?.setDevice(oldCameras[0].deviceId);
    }
  }
  AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
    console.log('mic changedDevice');
    // When plugging in a device, switch to a device that is newly plugged in.
    if (changedDevice.state === "ACTIVE") {
      localAudioTrack?.setDevice(changedDevice.device.deviceId);
      // Switch to an existing device when the current device is unplugged.
    } else if (changedDevice.device.label === localAudioTrack?.getTrackLabel()) {
      const oldMicrophones = await AgoraRTC.getMicrophones();
      oldMicrophones[0] && localAudioTrack?.setDevice(oldMicrophones[0].deviceId);
    }
  }
  async function createVideoLocalTracks(audioConfig?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig)
    : Promise<[IMicrophoneAudioTrack | undefined, ICameraVideoTrack | undefined]> {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig).then((res) => {
      setLocalAudioTrack(res);
      return res;
    }).catch((err) => {
      console.log('mic erorr');
      console.log(err);
      console.log(err?.code);
      if (err?.code === 'DEVICE_NOT_FOUND') {
        fetchError('Không tìm thấy thiết bị micro của bạn!');
      }
      return undefined;
    })
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig).then((res) => {
      setLocalVideoTrack(res);
      return res;
    }).catch((err) => {
      console.log('camera erorr');
      console.log(err);
      console.log(err?.code);
      if (err?.code === 'NOT_READABLE') {
        console.log('not readble');
        fetchError('Không tìm thấy camera của bạn!');
      }
      return undefined;
    })
    return [microphoneTrack, cameraTrack];
  }

  async function leave(errMsg, infoCall, user, action) {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setClientRTCStats(undefined);
    setLocalAudioTrackStats(undefined);
    setJoinState(false);
    setLocalVideoTrack(undefined);
    setLocalAudioTrack(undefined);
    setIsUserLeftLeave(false);
    setIsUserLeftJoin(false);
    setConn_state('');
    await client?.leave().then((res) => {
      createCallTracking({
        session: infoCall?.session || '',
        userCode: user?.userCode || '',
        action: action,
        isSuccess: true,
        error: errMsg || '',
      });
    }).catch((err) => {
      createCallTracking({
        session: infoCall?.session || '',
        userCode: user?.userCode || '',
        action: action,
        isSuccess: false, // Có nên bắn noti cho thằng kia k
        error: errMsg || '',
      }); // vô rồi nhưng tắt máy auto true, chưa vô nhưng tắt máy 
    });
  }

  async function join(appid: string, channel: string, token?: string, uid?: string | number | null, infoCall?: any, user?: any) {
    if (!client) return;
    if (infoCall?.typeCall === 'CALL') {
      const [microphoneTrack, cameraTrack] = await createVideoLocalTracks(
        { encoderConfig: "high_quality_stereo" }, {
        optimizationMode: "motion",
        encoderConfig: {
          width: { max: 1280, min: 720 },
          height: { min: 320, max: 320 },
          frameRate: 15,
          bitrateMin: 600,
          bitrateMax: 1000,
        }
      });
      await client.join(appid, channel, token || null, Number(uid))
        .then(async () => {
          let errorMsg = 0;
          if (microphoneTrack) {
            errorMsg = errorMsg + 1;
            console.log('publish microphone')
            localAudioTrack?.setVolume(1000);
            localAudioTrack?.setEnabled(true);
            client.publish([microphoneTrack]);
          };
          if (cameraTrack) {
            errorMsg = errorMsg + 1;
            console.log('publish camera')
            localVideoTrack?.setEnabled(true);
            localVideoTrack?.play("DOM_ELEMENT_ID");
            client.publish([cameraTrack]);
          }
          if (errorMsg !== 0) {
            setConn_state(client.connectionState);
            await changeStatusStartCall(true);
            await createCallTracking({
              session: infoCall?.session || '',
              userCode: user?.userCode || '',
              action: 'JOIN',
              isSuccess: true,
              error: '',
            });
            (window as any).client = client;
            (window as any).videoTrack = cameraTrack;
            setJoinState(true);
          } else {
          }
        })
        .catch((err) => {
          // leave(err?.message, infoCall, user);
        });
    } else {
      const [microphoneTrack] = await createCallLocalTracks(
        { encoderConfig: "high_quality_stereo", }
      );
      await client.join(appid, channel, token || null, Number(uid))
        .then(async () => {
          let errorMsg = 0;
          if (microphoneTrack) {
            errorMsg = errorMsg + 1;
            localAudioTrack?.setVolume(1000);
            localAudioTrack?.setEnabled(true);
            localAudioTrack?.play();
            client.publish([microphoneTrack]);
          }
          if (errorMsg !== 0) {
            setConn_state(client.connectionState);
            await createCallTracking({
              session: infoCall?.session || '',
              userCode: user?.userCode || '',
              action: 'JOIN',
              isSuccess: true,
              error: '',
            });
            (window as any).client = client;
            setJoinState(true);
          } else {
            console.log('error == 0');
          }
        })
        .catch((err) => {
          console.log('join error mic');
          // leave(err?.message, infoCall, user);
        });
    }
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleListUserPublished = (curState: ConnectionState, revState: ConnectionState, reason?: ConnectionDisconnectedReason | undefined) => {
      console.log('list-user-published');
    }
    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      console.log('user-published');
      // await client.subscribe(user, mediaType);
      console.log(mediaType);
      await client.subscribe(user, mediaType);
      if (mediaType === "video") {
        console.log("subscribe video success");
        user?.videoTrack?.play("DOM_ELEMENT_ID");
      }
      if (mediaType === "audio") {
        console.log("subscribe audio success");
        user?.audioTrack?.play();
      }
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserUnpublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      console.log('===========================================');
      console.log('user-unpublished');
      console.log(user)
      // await client.unsubscribe(user, mediaType);
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      console.log('user-joined');
      console.log(client.remoteUsers);
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
      setIsUserLeftJoin(true);
    }
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      console.log('user-left');
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
      // setRemoteUsers([]);
      setIsUserLeftLeave(true);
    }
    client.on('published-user-list', handleListUserPublished);
    client.on('user-published', handleUserPublished);

    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on("stream-added", (e: any) => {
      client.subscribe(e.stream, 'video');
    });

    client.on("stream-subscribed", (e: any) => {
      console.log("subscribe success");
      e.stream.play("DOM_ELEMENT_ID");
    });

    client.on("network-quality", (stats) => {
      console.log('network-quality')
      if (stats.downlinkNetworkQuality === stats.uplinkNetworkQuality && stats.downlinkNetworkQuality === network.status) return;
      if (stats.downlinkNetworkQuality !== stats.uplinkNetworkQuality) {
        if (stats.downlinkNetworkQuality > stats.uplinkNetworkQuality) {
          if (stats.uplinkNetworkQuality > 2) {
            setNetwork({ status: stats.uplinkNetworkQuality, title: returnStatusNetwork(stats.uplinkNetworkQuality), color: '#FE653B' });
          } else {
            setNetwork({ status: stats.uplinkNetworkQuality, title: returnStatusNetwork(stats.uplinkNetworkQuality), color: '#22C55E' });
          }
        } else {
          if (stats.downlinkNetworkQuality > 2) {
            setNetwork({ status: stats.downlinkNetworkQuality, title: returnStatusNetwork(stats.downlinkNetworkQuality), color: '#FE653B' });
          } else {
            setNetwork({ status: stats.downlinkNetworkQuality, title: returnStatusNetwork(stats.downlinkNetworkQuality), color: '#22C55E' });
          }
        }
      } else {
        if (stats.uplinkNetworkQuality > 2 || stats.downlinkNetworkQuality > 2) {
          setNetwork({ status: stats.downlinkNetworkQuality, title: returnStatusNetwork(stats.downlinkNetworkQuality), color: '#FE653B' });
        } else {
          setNetwork({ status: stats.uplinkNetworkQuality, title: returnStatusNetwork(stats.uplinkNetworkQuality), color: '#22C55E' });
        }
      }
    });
    client.on("exception", function (evt) {
      console.log(evt.code, evt.msg, evt.uid);
    });
    client.on("user-info-updated", function (evt) {
      console.log(evt);
    })
    client.on("connection-state-change", (curState, prevState, reason) => {
      // The sample code uses debug console to show the connection state. In a real-world application, you can add
      // a label or a icon to the user interface to show the connection state. 
      // Display the current connection state.
      console.log("Connection state has changed to :" + curState);
      // Display the previous connection state.
      console.log("Connection state was : " + prevState);
      // Display the connection state change reason.
      console.log("Connection state change reason : " + reason);
    });
    // client.on("peer-online", (val) => {
    //   console.log("============================@");
    //   console.log("peer-online", val)
    // })
    // client.on("peer-leave", (val) => {
    //   console.log("============================@");
    //   console.log("peer-leave", val)
    // })
    // client.on("stream-published", (val) => {
    //   console.log("============================@");
    //   console.log("stream-published", val)
    // })

    return () => {
      client.off('published-user-list', handleListUserPublished);
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-info-updated', (val: any) => {
        console.log(val)
      })
      client.off('user-left', (val: any) => {
        console.log(val);
      });
      client.off("network-quality", (stats: any) => {
        setNetwork({ status: 0, title: 'Đang kiểm tra chất lượng đường truyền', color: '#979797' });
      });
      client.off("exception", function (evt: any) {
        // console.log(evt.code, evt.msg, evt.uid);
      });
      client.off("connection-state-change", (evt: any) => { })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);
  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    localAudioTrackStats,
    clientRTCStats,
    isUserLeftLeave,
    isUserLeftJoin,
    conn_state,
    network
  };
}