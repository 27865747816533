import { Layout } from 'antd';
import React from 'react';
import { useAuthUser } from '../../utility/AuthHooks';
import AppContentView from '../AppContentView';
import AppInfoView from '../AppInfoView';
import MiniSidebarToggle from './MiniSidebarToggle';

import './layout.style.less'
// import useBasic from 'hooks/useBasic';
import ModalCall from 'components/call/index';

const AppLayout = () => {
  const { isAuthenticated } = useAuthUser();
  const { user } = useAuthUser();

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <MiniSidebarToggle />
      ) : (
        <Layout className='auth'>
          <AppContentView />
        </Layout>
      )}
      <AppInfoView />
      {user.userCode !== 'TMA_QT_KV' ? <ModalCall /> : null}
    </React.Fragment>
  )
}

export default React.memo(AppLayout);