// Base
const DEFAULT_LIST = {
  page: 1,
  pageSize: 20,
  totalPage: 1,
  total: 1,
  succeeded: false,
  message: '',
  data: []
}
const DEFAULT_LIST_IDENTITY = {
  isSuccess: false,
  message: '',
  data: []
};
const DEFAULT_CREATE = {
  isSuccess: false,
  message: '',
}
export const DEFAULT_SUCCEEDED = {
  succeeded: false,
  message: '',
  data: []
}
// Doctor
export const DEFAULT_LIST_DOCTOR = DEFAULT_LIST;

// Customer
export const DEFAULT_LIST_CUSTOMER = DEFAULT_LIST;
export const DEFAULT_READ_CUSTOMER = {
  succeeded: false,
  message: '',
  errorCode: '',
  data: []
}

// Symptom
export const DEFAULT_LIST_SYMPTOM = DEFAULT_LIST;

// Specialist
export const DEFAULT_LIST_SPECIALIST = DEFAULT_LIST;

// Hospital
export const DEFAULT_LIST_HOSPITAL = DEFAULT_LIST;

// Code
export const DEFAULT_LIST_CODE = DEFAULT_SUCCEEDED;
export const DEFAULT_CREATE_CODE = DEFAULT_CREATE;

// Message
export const DEFAULT_LIST_USER = {
  success: false,
  message: '',
  data: [],
}
export const DEFAULT_POSITION_MESSAGE = {
  count: 20,
  messageId: ''
}

// Setting
export const DEFAULT_GET_LIST_ROLE = DEFAULT_SUCCEEDED;
export const DEFAULT_CREATE_ROLE = {
  succeeded: false,
  message: '',
}
export const DEFAULT_GET_LIST_ROLE_FOR_ROLE_NAME = DEFAULT_SUCCEEDED;
export const DEFAULT_EDIT_ROLE_MODULE = {
  succeeded: false,
  message: '',
}

// Account
export const DEFAULT_GET_LIST_ACCOUNT_ADMIN = {
  page: 0,
  pageSize: 20,
  totalPage: 0,
  total: 0,
  succeeded: false,
  message: '',
  data: []
}
export const DEFAULT_GET_LIST_COMPANY = DEFAULT_SUCCEEDED;

// Admin
export const DEFAULT_INFO_ADMIN = {
  accountId: 0,
  employeeCode: "",
  fullName: "",
  phone: "",
  email: "",
  companyId: 0,
  companyName: "",
  roleId: 0,
  roleName: "",
}

//MemberCard
export const DEFAULT_MEMBER_CARD_FOR_CODE = {
  succeeded: false,
  message: '',
  data: {
    customerCode: '',
    customerName: '',
    memberCards: []
  }
}

// calendars

export const DEFAULT_CALENDAR_DATE_TIME = {
  succeeded: false,
  message: '',
  data: {
    group: '',
    calendarDate: [],
  }
}

export const DEFAULT_CREATE_BOOKING = {
  succeeded: false,
  message: '',
}

// product
export const DEFAULT_ATTRIBUTES_PRODUCT = {
  succeeded: false,
  message: '',
  data: []
}

// doctor
export const DEFAULT_READ_DOCTOR_EXCEL = DEFAULT_SUCCEEDED
export const DEFAULT_LIST_SPECIALIST_GROUP = {
  page: 1,
  pageSize: 20,
  totalPage: 0,
  total: 0,
  succeeded: false,
  message: null,
  data: []
}