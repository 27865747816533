import React from 'react';
import Icon from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import clsx from 'clsx';

import defaultConfig from '../../../../utility/AppContextProvider/defaultConfig';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';
import { MenuStyle } from '../../../../../shared/constants/AppEnums';
import { useAuthUser } from '../../../../utility/AuthHooks';

import './index.style.less';

interface IAppVerticalNav {
  isCollapsed: boolean;
}

const AppVerticalNav: React.FC<IAppVerticalNav> = ({ isCollapsed }) => {
  const { menuStyle, sidebarColorSet } = useSidebarContext();
  const { pathname } = useLocation();
  const history = useNavigate();
  const { data } = useAuthUser();
  const dataMerge = [...data,
  // {
  //   id: 16,
  //   title: 'Quản lý giao thẻ',
  //   type: 'item',
  //   decription: null,
  //   url: '/one-care-shipping',
  //   children: []
  // }
  ]
  return (
    <Menu
      theme={'dark'}
      mode='inline'
      className={clsx('app-main-sidebar-menu', {
        'menu-rounded': menuStyle === MenuStyle.ROUNDED,
        'menu-rounded rounded-menu-reverse':
          menuStyle === MenuStyle.ROUNDED_REVERSE,
        'menu-rounded standard-menu': menuStyle === MenuStyle.STANDARD,
        'menu-rounded curved-menu': menuStyle === MenuStyle.CURVED_MENU,
        'bg-color-menu':
          sidebarColorSet?.sidebarBgColor !==
          defaultConfig?.sidebar?.colorSet?.sidebarBgColor,
      })}
      inlineCollapsed={isCollapsed}
      defaultSelectedKeys={[dataMerge.find((i) => i.url === pathname)?.id || '']}
      defaultOpenKeys={[dataMerge.find((i) => i.type === 'collapse')?.children[0]?.id || undefined]}
      items={[...dataMerge].map((val) => {
        return val.type !== 'dividers' ? {
          key: val.id,
          label: val.title,
          type: val.type,
          icon: <Icon component={() => <img src={val.icon} alt='icon' />} />,
          children: (val?.children && val?.children?.length && val?.type !== 'item') ? val.children.filter((i: any) => i.behavior).map((item: any) => {
            return {
              key: item.id,
              label: item.title,
              icon: val.type !== 'collapse' ? <Icon component={() => <img src={val.icon} alt='icon' />} /> : null,
              type: item.type,
              onClick: item.type === 'item' ? () => {
                history(item.url);
              } : undefined,
              children: (item?.children && item?.children?.length !== 0 && item?.type !== 'item') ? item.children.filter((i: any) => i.behavior).map((i: any) => {
                return {
                  key: i.id,
                  label: i.title,
                  icon: <Icon component={() => <img src={val.icon} alt='icon' />} />,
                  type: i.type,
                  onClick: i.type === 'item' ? () => {
                    history(i.url);
                  } : undefined,
                }
              }) : null
            }
          }) : null,
          onClick: val.type === 'item' ? () => {
            history(val?.url || '/');
          } : undefined,
        } : null
      })}
    />
  );
};

export default AppVerticalNav;
