import { Avatar, Button } from 'antd'
import { ReactComponent as AvatarDefault } from 'assets/user/avatar-default.svg';
import React from 'react'

interface IModalPending {
  isZoom: boolean;
  handleOffCall: () => void;
}

const ModalPending: React.FC<IModalPending> = ({
  isZoom, handleOffCall
}) => {
  return (
    <div className='call-video-container'>
      <div className='box-call-video' style={{
        padding: isZoom ? 16 : 24,
      }}>
        <div className='box-image'>
          <div className='box-image-item'>
            <Avatar size={82} className='cr-user-info-avatar' src={<AvatarDefault style={{ width: 82, height: 82 }} />} />
          </div>
          <div className="circle" style={{ animationDelay: '0s' }}></div>
          <div className="circle" style={{ animationDelay: '1s' }}></div>
          <div className="circle" style={{ animationDelay: '2s' }}></div>
          <div className="circle" style={{ animationDelay: '3s' }}></div>
        </div>
        <p className='text-waiting'>Đang chờ phản hồi của khách hàng...</p>

        <Button onClick={handleOffCall} className='refuse-btn'>Dừng cuộc gọi</Button>
      </div>
    </div>
  )
}

export default ModalPending