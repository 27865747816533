import axios from 'axios';

export const BaseAPI = process.env.REACT_APP_API_URL;
export const BaseAPIV1 = process.env.REACT_APP_API_V1_URL;
export const BaseAPIChat = process.env.REACT_APP_API_URL_CHAT;
export const BaseAPIIdentity = process.env.REACT_APP_API_IDENTITY;
export const BaseAPIMessage = process.env.REACT_APP_API_MESSAGE;
export const BasePartnerImgPrivate = process.env.REACT_APP_PARTNER_IMG_PRIVATE;
export const BasePartnerImgChat = process.env.REACT_APP_PARTNER_IMG_CHAT;
export const BaseAPIVoice = process.env.REACT_APP_API_URL_VOICE;

const jwtAxios = axios.create({
  baseURL: BaseAPI, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtVoiceApi = axios.create({
  baseURL: BaseAPIVoice, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtLogin = axios.create({
  baseURL: BaseAPI, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtApiV1 = axios.create({
  baseURL: BaseAPIV1, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtApiIdentity = axios.create({
  baseURL: BaseAPIIdentity, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtAxiosChat = axios.create({
  baseURL: BaseAPIChat, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtApiMessage = axios.create({
  baseURL: BaseAPIMessage, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

export const jwtApiV1Upload = axios.create({
  baseURL: BaseAPIV1, // YOUR_API_URL HERE
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response && (err?.response?.status === 401 || err?.response?.status === 403)) {
      console.log('Need to logout user');
      delete jwtAxios.defaults.headers.common.Authorization;
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.reload();
    }
    return Promise.reject(err);
  },
);

jwtLogin.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response && (err?.response?.status === 401 || err?.response?.status === 403)) {
      console.log('Need to logout user');
      delete jwtLogin.defaults.headers.common.Authorization;
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.reload();
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token?: string, refreshToken?: string) => {
  if (token && refreshToken) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    jwtLogin.defaults.headers.common.Authorization = `Bearer ${token}`;
    jwtApiV1.defaults.headers.common.Authorization = `Bearer ${token}`;
    jwtApiIdentity.defaults.headers.common.Authorization = `Bearer ${token}`;
    jwtApiMessage.defaults.headers.common.Authorization = `Bearer ${token}`;
    jwtApiV1Upload.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    delete jwtLogin.defaults.headers.common.Authorization;
    delete jwtApiV1.defaults.headers.common.Authorization;
    delete jwtApiIdentity.defaults.headers.common.Authorization;
    delete jwtApiMessage.defaults.headers.common.Authorization;
    delete jwtApiV1Upload.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("info");
  }
};

export default jwtAxios;
