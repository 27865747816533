import { IInfoCall, IInfoTokenCall, IResActionCall, IResCreateCall, IResTracking } from "types/models/Agora";

export const CUSTOMER_CODE_CALL = "CUSTOMER_CODE_CALL";
export const CHANGE_OPEN_CALL = "CHANGE_OPEN_CALL";
export const CHANGE_STATUS_START_CALL = "CHANGE_STATUS_START_CALL";
export const CHANGE_INFO_CALL = "CHANGE_INFO_CALL";
export const CHANGE_INFO_TOKEN_CALL = "CHANGE_INFO_TOKEN_CALL";
export const GET_ACTION_CALL = "GET_ACTION_CALL";
export const CREATE_CALL = "CREATE_CALL";
export const CALL_TRACKING = "CALL_TRACKING";

export interface ChangeStatusStartCallActions {
  type: typeof CHANGE_STATUS_START_CALL;
  payload: boolean;
}

export interface UpdatecustomerCodeCallActions {
  type: typeof CUSTOMER_CODE_CALL;
  payload: string;
}

export interface UpdateOpenCallActions {
  type: typeof CHANGE_OPEN_CALL;
  payload: boolean;
}

export interface ChangeInfoCallActions {
  type: typeof CHANGE_INFO_CALL;
  payload: IInfoCall | null;
}

export interface ChangeInfoTokenCallActions {
  type: typeof CHANGE_INFO_TOKEN_CALL;
  payload: IInfoTokenCall | null;
}

export interface GetActionCallActions {
  type: typeof GET_ACTION_CALL;
  payload: IResActionCall;
}

export interface CreateCallActions {
  type: typeof CREATE_CALL;
  payload: IResCreateCall;
}

export interface CreateCallTrackingActions {
  type: typeof CALL_TRACKING;
  payload: IResTracking;
}

export type AgoraActionsTypes =
  | ChangeInfoCallActions
  | ChangeStatusStartCallActions
  | UpdatecustomerCodeCallActions
  | UpdateOpenCallActions
  | ChangeInfoTokenCallActions
  | GetActionCallActions
  | CreateCallActions
  | CreateCallTrackingActions;