import { useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import useSound from 'use-sound';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "@leo/utility/AuthHooks";
import useNotificationStore from "../zustand/reducers/Notification";
import useAgoraStore from "../zustand/reducers/Agora";
import useCommonStore from "../zustand/reducers/Common";
import useCustomerStore from "../zustand/reducers/Customer";
import NotificationSound from '../assets/sound/notification-sound.mp3'

var firebaseConfig = {
  apiKey: "AIzaSyCZA7uQng-Tjrl3ZC3qIAbi5BVxPfdhI7s",
  authDomain: "gotrustcare-e9549.firebaseapp.com",
  projectId: "gotrustcare-e9549",
  storageBucket: "gotrustcare-e9549.appspot.com",
  messagingSenderId: "97142819809",
  appId: "1:97142819809:web:90b6859796a45a6be7a0a0",
  measurementId: "G-ZR52X4GHLL"
};
const BaseAPISignalR = process.env.REACT_APP_API_URL_SIGNAL_R;
const DEFAULT_KEY_FIREBASE = process.env.REACT_APP_DEFAULT_FIREBASE_KEY
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const dataActions = ['REJECTGROUPCALL', 'REJECT', 'TURNOFFCALL']

export default function useBasic()
  : {
  } {
  const shouldRef = useRef(true);
  const location = useLocation();
  const history = useNavigate();
  const [play] = useSound(NotificationSound, { volume: 0.6 });
  const { isAuthenticated, user } = useAuthUser();
  const { notiValues } = useNotificationStore((state) => state);
  const { fetchError } = useCommonStore((state) => state);
  const { changeConnectNoti, changeNotificationValues } = useNotificationStore((state) => state)
  const { changeStatusStartCall, changeOpenCall, changeInfoTokenCallActions } = useAgoraStore((state) => state);
  const { updateUserFirebase } = useCustomerStore((state) => state);

  const playAudio = () => {
    play();
    var notif = new Notification('Bạn vừa có một tin nhắn mới!', { icon: "/logo.png" });
    notif.onclick = (event) => {
      event.preventDefault();
      if (location?.pathname !== '/message') {
        history('/message');
      }
      window.parent.parent.focus();
    }
  }
  const joinRoom = async () => {
    if (BaseAPISignalR && user.userCode !== 'TMA_QT_KV') {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(BaseAPISignalR)
          .configureLogging(LogLevel.Information)
          .build();

        connection.on("Receive", (message) => {
          if (location?.pathname !== '/message') {
            changeNotificationValues({ value: notiValues.value + 1 })
          }
        });

        await connection.start()
          .then((res) => changeConnectNoti(connection))
          .catch((err) => fetchError("Đã có lỗi khi kết nối, vui lòng tải lại trang!"));
      } catch (err) {
        console.log('err connection noti');
        console.log(err);
      }
    }
  }

  useEffect(() => {
    if (notiValues.value !== 0) {
      playAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notiValues])

  useEffect(() => {
    if (shouldRef.current && isAuthenticated) {
      shouldRef.current = false;
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then(function (registration) {
            console.log('Registration successful, scope is:', registration.scope);
          }).catch(function (err) {
            console.log('Service worker registration failed, error:', err);
          });
        // Listen to messages from service workers.
        navigator.serviceWorker.addEventListener('message', function (data) {
          if (data.data.data) {
            if (dataActions.includes(data?.data?.data?.action?.toUpperCase())) {
              changeStatusStartCall(false);
              changeOpenCall(false);
              changeInfoTokenCallActions(null);
            } else {
              changeInfoTokenCallActions({ ...data.data.data, typeCall: 'RECEIVE' });
              changeOpenCall(true);
            }
          }
        });
      }
      // user thoat trinh duyet thi remove user o backend, de ngan khong nhan cuoc goi ao
      //token cua firebase
      getToken(messaging, { vapidKey: DEFAULT_KEY_FIREBASE }).then((currentToken) => {
        if (currentToken) {
          updateUserFirebase(user.userCode, currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });
      //Foreground
      onMessage(messaging, (payload: any) => {
        console.log('Message received Foreground. ', payload);

        if (!dataActions.includes(payload?.data?.action?.toUpperCase())) {
          var notif = new Notification(payload.data?.body!, { icon: "/logo.png" });

          notif.onclick = (event) => {
            event.preventDefault();
            window.parent.parent.focus();
          }
          if (payload?.data) {
            changeStatusStartCall(false);
            changeInfoTokenCallActions({ ...payload.data, typeCall: 'RECEIVE' });
            changeOpenCall(true);
          }
        } else {
          changeOpenCall(false);
          changeInfoTokenCallActions(null);
        }
      });
      // Things to do before unloading/closing the tab
      // const disconnectedBeforeUnload = async (ev) => {
      //   ev.preventDefault();
      //   console.log('beforeunload event triggered');
      //   return (ev.returnValue = 'Are you sure you want to exit?');
      // }
      joinRoom();

      // // Setup the `beforeunload` event listener
      // window.addEventListener("beforeunload", (ev) => {
      //   ev.preventDefault();
      //   return disconnectedBeforeUnload;
      // });

      return () => {
        // window.removeEventListener('beforeunload', disconnectedBeforeUnload);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
  }
}