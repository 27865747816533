import { ILocalVideoTrack, IRemoteVideoTrack, ILocalAudioTrack, IRemoteAudioTrack } from "agora-rtc-sdk-ng";
import React, { useRef, useEffect } from "react";

import { IInfoTokenCall } from "types/models/Agora";

export interface VideoPlayerProps {
	videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
	audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
	label: string;
	type: string;
	infoTokenCall: IInfoTokenCall | null;
};

const MediaPlayer: React.FC<VideoPlayerProps> = (props) => {
	const container = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!container.current) return;
		props.videoTrack?.play(container.current);
		return () => {
			props.videoTrack?.stop();
		};
	}, [container, props.videoTrack]);

	useEffect(() => {
		if (props.audioTrack) {
			props.audioTrack?.play();
		}
		return () => {
			props.audioTrack?.stop();
		};
	}, [props.audioTrack]);

	return (
		<div className='video-container'>
			<div className="video-item">
				{props.type === 'me' ?
					<div className='video-me' ref={container} />
					:
					<div className='video-you' ref={container} />
				}
			</div>
			{/* <div className="video-item">
				<div className='video-me' ref={container} />
				<div className='video-you' ref={container} />
			</div> */}
		</div>
	);
}

export default MediaPlayer;