import { jwtVoiceApi } from '@leo/services/auth/jwt-auth/jwt-api';
import { AgoraActionsTypes, CALL_TRACKING, CHANGE_INFO_CALL, CHANGE_INFO_TOKEN_CALL, CHANGE_OPEN_CALL, CHANGE_STATUS_START_CALL, CREATE_CALL, CUSTOMER_CODE_CALL, GET_ACTION_CALL } from 'types/actions/Agora.actions';
import { DEFAULT_LIST_REQUEST_DOCTOR } from 'types/actions/ListRequest.actions';
import { IInfoCall, IInfoTokenCall, IReqActionCall, IReqCreateCall, IResActionCall, IResCreateCall, IResTracking, IReqTracking } from 'types/models/Agora';
import { create } from 'zustand';

export const DEFAULT_TRACKING = {
  success: false,
  message: "",
  code: 0
};
export const DEFAULT_CRAETE_CALL = {
  success: false,
  message: "",
  code: 0,
  data: null,
}

const initialAgora: {
  infoCall: IInfoCall | null;
  statusStartCall: boolean;
  customerCodeCall: string;
  openCall: boolean;
  uidCall: string | number | null;
  callTrackingInfo: IResTracking;
  createCallInfo: IResCreateCall,
  infoTokenCall: IInfoTokenCall | null;
  getActionCall: IResActionCall;
} = {
  infoCall: null,
  statusStartCall: false,
  customerCodeCall: '',
  openCall: false,
  uidCall: null,
  callTrackingInfo: DEFAULT_TRACKING,
  createCallInfo: DEFAULT_CRAETE_CALL,
  infoTokenCall: null,
  getActionCall: DEFAULT_CRAETE_CALL,
}

const AgoraReducers = (state = initialAgora, action: AgoraActionsTypes) => {
  switch (action.type) {
    case CHANGE_INFO_CALL:
      return {
        ...state,
        infoCall: action.payload,
      };
    case CHANGE_STATUS_START_CALL:
      return {
        ...state,
        statusStartCall: action.payload,
      };
    case CUSTOMER_CODE_CALL:
      return {
        ...state,
        customerCodeCall: action.payload,
      };
    case CHANGE_OPEN_CALL:
      return {
        ...state,
        openCall: action.payload,
      }
    case CHANGE_INFO_TOKEN_CALL:
      return {
        ...state,
        infoTokenCall: action.payload,
      };
    case GET_ACTION_CALL:
      return {
        ...state,
        getActionCall: action.payload
      }
    case CREATE_CALL:
      return {
        ...state,
        createCallInfo: action.payload,
      }
    case CALL_TRACKING:
      return {
        ...state,
        callTrackingInfo: action.payload,
      }
    default:
      return state;
  }
};

interface IAgoraStore {
  infoCall: IInfoCall | null;
  statusStartCall: boolean;
  customerCodeCall: string;
  openCall: boolean;
  uidCall: string | number | null;
  infoTokenCall: IInfoTokenCall | null;
  getActionCall: IResActionCall;
  createCallInfo: IResCreateCall;
  callTrackingInfo: IResTracking;
  changeInfoCall: (data: IInfoCall | null) => Promise<boolean>;
  changeInfoTokenCallActions: (data: IInfoTokenCall | null) => Promise<boolean>;
  changeStatusStartCall: (data: boolean) => Promise<boolean>;
  changeCustomerCodeCall: (data: string) => Promise<boolean>;
  changeOpenCall: (data: boolean) => Promise<boolean>;
  getActionCallActions: (data: IReqActionCall) => Promise<{ succeeded: boolean, message: string }>;
  createCallActions: (data: IReqCreateCall) => Promise<{ succeeded: boolean, message: string }>;
  createCallTracking: (data: IReqTracking) => Promise<{ succeeded: boolean, message: string }>;
  changeDefaultCallActions: () => void;
}

const useAgoraStore = create<IAgoraStore>((set) => ({
  ...initialAgora,
  infoCall: null,
  statusStartCall: false,
  customerCodeCall: '',
  openCall: false,
  uidCall: null,
  infoTokenCall: null,
  createCallInfo: DEFAULT_CRAETE_CALL,
  getActionCall: DEFAULT_CRAETE_CALL,
  callTrackingInfo: DEFAULT_TRACKING,
  changeInfoCall: async (data: IInfoCall | null) => {
    await set((state) => AgoraReducers(state, { type: CHANGE_INFO_CALL, payload: data }))
    return true;
  },
  changeInfoTokenCallActions: async (data: IInfoTokenCall | null) => {
    await set((state) => AgoraReducers(state, { type: CHANGE_INFO_TOKEN_CALL, payload: data }))
    return true;
  },
  changeStatusStartCall: async (data: boolean) => {
    await set((state) => AgoraReducers(state, { type: CHANGE_STATUS_START_CALL, payload: data }))
    return true;
  },
  changeCustomerCodeCall: async (data: string) => {
    await set((state) => AgoraReducers(state, { type: CUSTOMER_CODE_CALL, payload: data }))
    return true;
  },
  changeOpenCall: async (data: boolean) => {
    await set((state) => AgoraReducers(state, { type: CHANGE_OPEN_CALL, payload: data }))
    return true;
  },
  getActionCallActions: async (data: IReqActionCall) => {
    let result = { succeeded: false, message: '' };
    const actions: any = await jwtVoiceApi.get(`v2/api/call/${data.session}/${data.code}/${data.userCode}/${data.actionCall}`)
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          result = { succeeded: true, message: '!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_LIST_REQUEST_DOCTOR;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_LIST_REQUEST_DOCTOR;
      })
    set((state) => AgoraReducers(state, { type: GET_ACTION_CALL, payload: actions }))
    return result;
  },
  createCallActions: async (data: IReqCreateCall) => {
    let result = { succeeded: false, message: '' };
    const actions: any = await jwtVoiceApi.post(`v2/api/call/personal`, data)
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          result = { succeeded: true, message: '!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_CRAETE_CALL;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_CRAETE_CALL;
      })
    set((state) => AgoraReducers(state, { type: CREATE_CALL, payload: actions }))
    return result;
  },
  createCallTracking: async (data: IReqTracking) => {
    let result = { succeeded: false, message: '' };
    const actions: any = await jwtVoiceApi.post(`v2/api/call/tracking`, data)
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          result = { succeeded: true, message: '!' };
          return data.data;
        } else {
          result = { succeeded: false, message: data.data.message ?? 'Đã có lỗi gì đó xảy ra, vui lòng thử lại!' };
          return DEFAULT_TRACKING;
        }
      })
      .catch((error) => {
        result = { succeeded: false, message: error?.response?.data?.message ?? error?.message };
        return DEFAULT_TRACKING;
      })
    set((state) => AgoraReducers(state, { type: CALL_TRACKING, payload: actions }))
    return result;
  },
  changeDefaultCallActions: () => {
    set((state) => AgoraReducers(state, { type: CALL_TRACKING, payload: DEFAULT_CRAETE_CALL }))
  }
}))
export default useAgoraStore;
