import { IResponeCalendarsDateTime } from "types/models/CreateRequest";
import {
  IResponeDoctorOnlineBooking, IResponeStatusDoctorOnlineBooking, IResponeMedicineDelivery,
  IResponeSpecialist, IResponeDiseaseSymptom, IResponeListDoctor, IResponeSecondOpinion,
  IRequestEditDoctorOnlineBookings,
  IResponeEditSecondOpinions,
  IResponeEditMedicineDelivery,
  IResponeDoctorOnlineResult,
  IResponeGetPrescription,
  IResponeHealthTestBooking,
  IResponeStatusHealthTestBooking,
  IResponeHospitalBookingTKYK,
  IResponeStatusHospitalBookingTKYK,
  IResponeEditHealthTestBooking,
  IResponeGetListHospitalTKYK,
  IResponeGetPartnerBooking,
  IResponeGetPartnerLink,
  IResponeEditHospitalTKYK,
  IResponeGetCustomerForVhealth,
  IResponeTMAPartnerCodes
} from "types/models/ListRequest";

export const GET_DOCTOR_DIRECT_CALL = "GET_DOCTOR_DIRECT_CALL";
export const GET_DOCTOR_ONLINE_BOOKING = "GET_DOCTOR_ONLINE_BOOKING";
export const UPDATE_STATUS_DOCTOR_DIRECT_CALL = "UPDATE_STATUS_DOCTOR_DIRECT_CALL";
export const UPDATE_STATUS_DOCTOR_ONLINE_BOOKING = "UPDATE_STATUS_DOCTOR_ONLINE_BOOKING";
export const UPDATE_STATUS_SECOND_OPINION_BOOKING = "UPDATE_STATUS_SECOND_OPINION_BOOKING";
export const UPDATE_STATUS_MEDICINE_DELIVERY_BOOKING = "UPDATE_STATUS_MEDICINE_DELIVERY_BOOKING";
export const SPECIALIST_FOR_DOCTOR_DIRECT_CALL = "SPECIALIST_FOR_DOCTOR_DIRECT_CALL";
export const SPECIALIST_FOR_DOCTOR_ONLINE_BOOKING = "SPECIALIST_FOR_DOCTOR_ONLINE_BOOKING";
export const GET_DISEASE_SYMPTOM = "GET_DISEASE_SYMPTOM";
export const GET_LIST_DOCTOR = "GET_LIST_DOCTOR";
export const GET_LIST_SECOND_OPINION = "GET_LIST_SECOND_OPINION";
export const GET_LIST_MEDICINE_DELIVERY = "GET_LIST_MEDICINE_DELIVERY";
export const EDIT_DOCTOR_ONLINE_BOOKING = "EDIT_DOCTOR_ONLINE_BOOKING";
export const EDIT_SECOND_OPINIONS = "EDIT_SECOND_OPINIONS";
export const EDIT_MEDICINE_DELIVERY = "EDIT_MEDICINE_DELIVERY";
export const CREATE_DOCTOR_ONLINE_RESULT = "CREATE_DOCTOR_ONLINE_RESULT";
export const GET_PRESCRIPTION_FOR_CODE = "GET_PRESCRIPTION_FOR_CODE";
export const GET_HEALTH_TEST_BOOKING = "GET_HEALTH_TEST_BOOKING";
export const UPDATE_STATUS_HEALTH_TEST_BOOKING = "UPDATE_STATUS_HEALTH_TEST_BOOKING";
export const GET_HOSPITAL_BOOKING_TKYK = "GET_HOSPITAL_BOOKING_TKYK";
export const UPDATE_STATUS_HOSPITAL_BOOKING_TKYK = "UPDATE_STATUS_HOSPITAL_BOOKING_TKYK";
export const EDIT_HEALTH_TEST_BOOKING = "EDIT_HEALTH_TEST_BOOKING";
export const GET_LIST_HOSPITAL_TKYK = "GET_LIST_HOSPITAL_TKYK";
export const GET_CALENDARS_BOOKING_TKYK = "GET_CALENDARS_BOOKING_TKYK";
export const GET_PARTNER_BOOKING = "GET_PARTNER_BOOKING";
export const GET_PARTNER_LINK = "GET_PARTNER_LINK";
export const EDIT_HOSPITAL_TKYK = "EDIT_HOSPITAL_TKYK";
export const GET_CUSTOMER_FOR_VHEALTH = "GET_CUSTOMER_FOR_VHEALTH";
export const GET_TMA_PARTNER_CODES = "GET_TMA_PARTNER_CODES";

export const DEFAULT_LIST_REQUEST_DOCTOR = {
  succeeded: false,
  message: '',
  data: [],
}

export interface GetDoctorOnlineBookingActions {
  type: typeof GET_DOCTOR_ONLINE_BOOKING;
  payload: IResponeDoctorOnlineBooking;
}

export interface UpdateStatusDoctorOnlineBookingActions {
  type: typeof UPDATE_STATUS_DOCTOR_ONLINE_BOOKING;
  payload: IResponeStatusDoctorOnlineBooking;
}

export interface UpdateStatusSecondOpinionBookingActions {
  type: typeof UPDATE_STATUS_SECOND_OPINION_BOOKING;
  payload: IResponeStatusDoctorOnlineBooking;
}

export interface UpdateStatusMedicineDeliveryBookingActions {
  type: typeof UPDATE_STATUS_MEDICINE_DELIVERY_BOOKING;
  payload: IResponeStatusDoctorOnlineBooking;
}

export interface UpdateDoctorOnlineBookingActions {
  type: typeof EDIT_DOCTOR_ONLINE_BOOKING;
  payload: IRequestEditDoctorOnlineBookings;
}

export interface UpdateSecondOpinionsBookingActions {
  type: typeof EDIT_SECOND_OPINIONS;
  payload: IResponeEditSecondOpinions;
}

export interface UpdateMedicineDeliveryBookingActions {
  type: typeof EDIT_MEDICINE_DELIVERY;
  payload: IResponeEditMedicineDelivery;
}

export interface GetSpecialistForDoctorOnlineBookingActions {
  type: typeof SPECIALIST_FOR_DOCTOR_ONLINE_BOOKING;
  payload: IResponeSpecialist;
}

export interface GetListDiseaseSymptomActions {
  type: typeof GET_DISEASE_SYMPTOM;
  payload: IResponeDiseaseSymptom;
}

export interface GetListDoctorActions {
  type: typeof GET_LIST_DOCTOR;
  payload: IResponeListDoctor;
}

export interface GetListSecondOpinionActions {
  type: typeof GET_LIST_SECOND_OPINION;
  payload: IResponeSecondOpinion;
}

export interface GetListMedicineDeliveryActions {
  type: typeof GET_LIST_MEDICINE_DELIVERY;
  payload: IResponeMedicineDelivery;
}

export interface CreateDoctorOnlineResultActions {
  type: typeof CREATE_DOCTOR_ONLINE_RESULT;
  payload: IResponeDoctorOnlineResult;
}

export interface GetPrescriptionForCodeActions {
  type: typeof GET_PRESCRIPTION_FOR_CODE;
  payload: IResponeGetPrescription;
}

export interface GetHealthTestBookingActions {
  type: typeof GET_HEALTH_TEST_BOOKING;
  payload: IResponeHealthTestBooking;
}

export interface UpdateStatusHealthTestBookingActions {
  type: typeof UPDATE_STATUS_HEALTH_TEST_BOOKING;
  payload: IResponeStatusHealthTestBooking;
}

export interface GetHospitalBookingTKYKActions {
  type: typeof GET_HOSPITAL_BOOKING_TKYK;
  payload: IResponeHospitalBookingTKYK;
}

export interface UpdateStatusHospitalBookingTKYKActions {
  type: typeof UPDATE_STATUS_HOSPITAL_BOOKING_TKYK;
  payload: IResponeStatusHospitalBookingTKYK;
}

export interface EditHealthTestBookingActions {
  type: typeof EDIT_HEALTH_TEST_BOOKING;
  payload: IResponeEditHealthTestBooking;
}

export interface GetListHospitalTKTActions {
  type: typeof GET_LIST_HOSPITAL_TKYK;
  payload: IResponeGetListHospitalTKYK;
}

export interface GetCalendarsBookingTKYKActions {
  type: typeof GET_CALENDARS_BOOKING_TKYK;
  payload: IResponeCalendarsDateTime;
}

export interface GetPartnerBookingActions {
  type: typeof GET_PARTNER_BOOKING;
  payload: IResponeGetPartnerBooking;
}

export interface GetPartnerLinkActions {
  type: typeof GET_PARTNER_LINK;
  payload: IResponeGetPartnerLink;
}

export interface EditHospitalTKYKActions {
  type: typeof EDIT_HOSPITAL_TKYK;
  payload: IResponeEditHospitalTKYK;
}

export interface GetCustomerForVHealthActions {
  type: typeof GET_CUSTOMER_FOR_VHEALTH;
  payload: IResponeGetCustomerForVhealth;
}

export interface GetTMAPartnerCodesActions {
  type: typeof GET_TMA_PARTNER_CODES;
  payload: IResponeTMAPartnerCodes;
}

export type ListRequestActionsTypes =
  GetDoctorOnlineBookingActions |
  UpdateStatusDoctorOnlineBookingActions |
  UpdateStatusSecondOpinionBookingActions |
  UpdateStatusMedicineDeliveryBookingActions |
  GetSpecialistForDoctorOnlineBookingActions |
  GetListDiseaseSymptomActions |
  GetListDoctorActions |
  GetListSecondOpinionActions |
  GetListMedicineDeliveryActions |
  UpdateDoctorOnlineBookingActions |
  UpdateSecondOpinionsBookingActions |
  UpdateMedicineDeliveryBookingActions |
  CreateDoctorOnlineResultActions |
  GetPrescriptionForCodeActions |
  GetHealthTestBookingActions |
  UpdateStatusHealthTestBookingActions |
  GetHospitalBookingTKYKActions |
  UpdateStatusHospitalBookingTKYKActions |
  EditHealthTestBookingActions |
  GetListHospitalTKTActions |
  GetCalendarsBookingTKYKActions |
  GetPartnerBookingActions |
  GetPartnerLinkActions |
  EditHospitalTKYKActions |
  GetCustomerForVHealthActions |
  GetTMAPartnerCodesActions;